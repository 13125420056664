import React, {useState, useEffect} from 'react'
import {
  Avatar,
  Box, Container, FormLabel,
  Grid, Link,
  makeStyles, MenuItem,
  Paper, Select,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar
} from "@material-ui/core"
import { BootstrapInput } from "../../../util"
import { setOnboardData } from "../system/system_slice"
import SharedStyles from '../../../shared_styles'
import {useSelector} from "react-redux"
import Util from "../../../../lib/util"

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '75%',
    maxWidth: '300px',
    marginBottom: theme.spacing(3)
  },
  hand: {
    width: '20%',
    maxWidth: '90px',
    marginBottom: theme.spacing(3)
  },

}))



const Start = ({name}) => {
  const classes = useStyles();
  const styles = SharedStyles();
  const {networkInfo} = useSelector(state => state.system);


  return (
    <Box className={styles.centerElements} style={{flexDirection:'column'}}>
      <Util.PoweredByGreenwoodx networkKey={networkInfo.Key}/>
      <img src={networkInfo.Logo} className={classes.logo}/>
      <span style={{fontSize: '5rem'}}>
      👋🏿
      </span>
      <Typography variant="h4">Hi, {name}</Typography>
      <Typography variant="subtitle1">We need to review some things before you can get started.</Typography>
    </Box>
  );
}

export default Start;
