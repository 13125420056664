import React, {useState, useEffect} from 'react'
import {
  Avatar,
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  TextField, Grid, FormControl, FormLabel, Select, MenuItem,
} from "@material-ui/core"
import CalendarTodayIcon from "@material-ui/icons/CalendarToday"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import { useDispatch, useSelector } from "react-redux"
import {setOnboardData} from "../system/system_slice"
import {BootstrapInput} from "../../../util"

import SharedStyles from '../../../shared_styles'
import { STATEMENT_CHAR_LIMIT, REGISTRATION_KEYS } from "../../../../lib/constants"


const Info = () => {

  const dispatch = useDispatch();
  const styles = SharedStyles();
  const {onboard, user, networkInfo} = useSelector((state) => state.system);
  const [statement, setStatement] = useState('');

  useEffect(() => {
    if (user.Statement) {
      setStatement(user.Statement);
      dispatch(setOnboardData({key: REGISTRATION_KEYS.STATEMENT, value: user.Statement}));
    }
  }, [])

  const handleTextField = (event) => {
    switch(event.target.name) {
      case REGISTRATION_KEYS.STATEMENT:
        if (event.target.value.length > STATEMENT_CHAR_LIMIT) return;
        setStatement(event.target.value);
        break;
    }
  }

  const saveFormValue = (event) => {
    dispatch(setOnboardData({key: event.target.name, value: event.target.value}));
  }

  const examples = [
    `I have a passion for building, connecting, and empowering communities through compelling storytelling, innovative policy, and meaningful service.`,
  ]

  return (
    <Container maxWidth="sm" style={{paddingTop:' 30px'}}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FormLabel component="legend">What's a statement that expresses your mission or goals that we can share with the people you will meet.</FormLabel>
          <TextField
            id={REGISTRATION_KEYS.STATEMENT}
            name={REGISTRATION_KEYS.STATEMENT}
            label="Personal statement"
            variant="outlined"
            fullWidth
            required
            value={statement}
            onChange={handleTextField}
            margin="dense"
            multiline
            rows={4}
            rowsMax={7}
            helperText={`${STATEMENT_CHAR_LIMIT} character or less.`}
            onBlur={saveFormValue}
          />
          <Box style={{color: 'grey', marginTop: '10px'}}>
            <strong>Example</strong>
            <br />
            {examples[0]}
          </Box>
        </Grid>
      </Grid>
    </Container>

  )
}

export default Info;
