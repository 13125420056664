import React, {useState, useEffect} from 'react'
import Layout from "../../../layout"
import {
  Box,
  Container,
  Button,
  makeStyles, BottomNavigation, Typography, ThemeProvider, CircularProgress, Chip,
} from "@material-ui/core"
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { ElementOrLoader } from "../../../util"
import MobileStepper from '@material-ui/core/MobileStepper';
import SharedStyles from '../../../shared_styles'

import Welcome from "./welcome"
import Start from "./start"
import TopicSelect from "../settings/topic_select"
import { getAuthToken, isAuthenticated, setAuthToken } from "../../../../lib/auth"
import axios from "axios"
import {
  API_ADDRESS,
  APP_BAR_STATE,
  USER_PHASE,
  USER_TYPE,
  createEnum,
  LINKEDIN_REGEX, REGISTRATION_KEYS,
} from "../../../../lib/constants"
import { apiGet, apiPost, apiSaveAvailability, fetchTopics, firstLoad } from "../../../../lib/api"
import { useDispatch, useSelector } from "react-redux"
import Availability from "../settings/availability"
import clsx from 'clsx';
import SwipeableViews from 'react-swipeable-views';
import Info from "./info"
import {setSystemData, setOnboardData} from "../system/system_slice"
import {navigate} from "@reach/router"
import Registration from "./registration"


const useStyles = makeStyles((theme) => ({
  slide: {
    height: '100%',
    paddingTop: theme.spacing(2)
  }
}));


const ONBOARD_STEPS = createEnum(['START','WELCOME', 'REGISTRATION', 'KNOWN_TOPICS', 'INTERESTS', 'AVAILABILITY', 'STATEMENT']);

const Onboard = ({params}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = SharedStyles();

  const {appBar, canonicalTopics, onboard, user, networkInfo} = useSelector((state) => state.system);

  const [loading, setLoading] = useState(true);
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [nextLoading, setNextLoading] = useState(true);

  const [onboardSteps, setOnboardSteps] = useState([]);
  const [onboardStep, setOnboardStep] = useState(0);

  const [nextButtonLabel, setNextButtonLabel] = useState('');
  const [registrationErrorMap, setRegistrationErrorMap] = useState({});
  const [prevUserType, setPrevUserType] = useState('');


  useEffect(() => {
    // If this is an initial signup there will be a code here to verify the user.
    // TODO: Remove the check for isAuthenticated when we get rid of the other registration page.
    if (!isAuthenticated() && !('code' in params)) {
      navigate('/login?url=/onboard');
    }

    const data = {};
    if ('code' in params) {
      data['code'] = params['code'];
    }
    apiGet('user/onboard-load', data)
      .then((response) => {
        if (response.error) {
          alert("who are you?") ;
          return;
        }
        if (response.user.Phase && response.user.Phase != USER_PHASE.ONBOARD) {
          return navigate('/app');
        }

        // Save temporary Auth Token
        setAuthToken(response.temporary_token);

        const user = response.user;
        dispatch(setSystemData([
          {key: 'user', value: user},
          {key: 'networkInfo', value: response.network}
        ]));
        if (user.Type != USER_TYPE.STUDENT) {
          response.user.KnownTopics.forEach((item) => {
            dispatch(setOnboardData({key: REGISTRATION_KEYS.KNOWN_TOPICS, value: {'id':item}}));
          })
        }
        response.user.Interests.forEach((item) => {
          dispatch(setOnboardData({key: REGISTRATION_KEYS.INTEREST_TOPICS, value: {'id':item}}));
        })
        if (response.network.DefaultUserType != USER_TYPE.ANY) {
          dispatch(setOnboardData({key: REGISTRATION_KEYS.USER_TYPE, value: response.network.DefaultUserType}));
        }
        setLoading(false);
        setNextLoading(false);

      })
      .then(()=> {
        fetchTopics(dispatch);
      });

  }, [])

  useEffect(() => {
    if (networkInfo && prevUserType == onboard[REGISTRATION_KEYS.USER_TYPE]) return;
    setPrevUserType(onboard[REGISTRATION_KEYS.USER_TYPE]);
    deriveOnboardSteps();
  }, [onboard])

  useEffect(() => {
    deriveOnboardSteps();
  }, [networkInfo])

  useEffect(() => {
    // If nextLoading changed to false while on the availability page then
    // loading has finished.
    if (!nextLoading && activeStep == 3) {

    }
  }, [nextLoading])

  const deriveOnboardSteps = () => {
    let onboard_steps = [
      ONBOARD_STEPS.START,
      ONBOARD_STEPS.WELCOME,
      ONBOARD_STEPS.REGISTRATION,
    ];

    if (!networkInfo.SkipTopics) {
      if( onboard[REGISTRATION_KEYS.USER_TYPE] == USER_TYPE.NORMAL) {
        onboard_steps.push(ONBOARD_STEPS.KNOWN_TOPICS)
      }
      onboard_steps.push(ONBOARD_STEPS.INTERESTS)
    }
    if (onboard[REGISTRATION_KEYS.USER_TYPE] == USER_TYPE.NORMAL) {
      onboard_steps.push(ONBOARD_STEPS.AVAILABILITY)
    }
    onboard_steps.push(ONBOARD_STEPS.STATEMENT)
    setOnboardSteps(onboard_steps);
  }


  // Validations
  const hasFieldError = (fieldValue) => {
    if (!fieldValue) {
      return true
    }
    if (typeof fieldValue === 'string') {
      return fieldValue.length == 0;
    }
    return fieldValue == 0;
  };
  const linkedInHasError = (url) => {
    return !LINKEDIN_REGEX.test(url);
  }

  const validate = () => {
    const errorMap = {}
    const validationMap = {};
    validationMap[REGISTRATION_KEYS.FIRST_NAME] = [onboard[REGISTRATION_KEYS.FIRST_NAME]];
    validationMap[REGISTRATION_KEYS.LAST_NAME] = [onboard[REGISTRATION_KEYS.LAST_NAME]];
    validationMap[REGISTRATION_KEYS.EMAIL] = [onboard[REGISTRATION_KEYS.EMAIL]];
    validationMap[REGISTRATION_KEYS.GENDER] = [onboard[REGISTRATION_KEYS.GENDER]];
    validationMap[REGISTRATION_KEYS.RACE] = [onboard[REGISTRATION_KEYS.RACE]]
    validationMap[REGISTRATION_KEYS.USER_TYPE] = [onboard[REGISTRATION_KEYS.USER_TYPE]];
    if(networkInfo.ShowLinkedIn) {
      validationMap[REGISTRATION_KEYS.LINKEDIN] = [onboard[REGISTRATION_KEYS.LINKEDIN], linkedInHasError];
    }

    let isValid = true;
    for (const key in validationMap) {
      let hasError;
      // If a validation function is supplied use that, if not use the default.
      if (validationMap[key].length == 2) {
        hasError = validationMap[key][1](validationMap[key][0]);
      } else {
        hasError = hasFieldError(validationMap[key][0]);
      }
      errorMap[key] = hasError;
      if (isValid && hasError) {
        isValid = false;
      }
    }
    setRegistrationErrorMap(errorMap);
    return isValid;
  }


  const handleNext = () => {
    const step = onboardSteps[activeStep];
    if (step == ONBOARD_STEPS.REGISTRATION && !validate()) {

    }
    else if(step == ONBOARD_STEPS.KNOWN_TOPICS && Object.keys(onboard[REGISTRATION_KEYS.KNOWN_TOPICS]).length == 0) {
      alert('You need to select at least one topic.')
    } else if(step == ONBOARD_STEPS.INTERESTS && Object.keys(onboard[REGISTRATION_KEYS.INTEREST_TOPICS]).length == 0) {
      alert('You need to select at least one topic.')
    } else if (step == ONBOARD_STEPS.AVAILABILITY) {
      if (!window.confirm('Are you sure your availabilities are set correctly?')) {
        return;
      }
      // Availability
      setNextLoading(true);
      apiSaveAvailability(dispatch)
        .then(() => {
          setNextLoading(false);
          setNextButtonLabel('Finish')
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
        })
        .catch(()=>setNextLoading(false));
    } else if (step == ONBOARD_STEPS.STATEMENT) {
      if (onboard[REGISTRATION_KEYS.STATEMENT] && onboard[REGISTRATION_KEYS.STATEMENT].length < 10) {
        return alert("Your personal statement is too short. If you copied and pasted add a space at the end.")
      }
      if (networkInfo.ShowLinkedIn && linkedInHasError(onboard[REGISTRATION_KEYS.LINKEDIN])) {
        return alert("There's something wrong with your LinkedIn URL");
      }
      // Finish
      const data = Object.assign({}, onboard);
      if (!networkInfo.SkipTopics) {
        data[REGISTRATION_KEYS.INTEREST_TOPICS] = Object.keys(onboard[REGISTRATION_KEYS.INTEREST_TOPICS]);
        if (onboard[REGISTRATION_KEYS.USER_TYPE] != USER_TYPE.STUDENT) {
          data[REGISTRATION_KEYS.KNOWN_TOPICS] = Object.keys(onboard[REGISTRATION_KEYS.KNOWN_TOPICS]);
        }
      }

      apiPost('user/onboard-save', data)
        .then((response) => {
          if (response.error) {
            alert(response.error_message)
            return;
          }
          setAuthToken(response.access_token);
          navigate('/app');
        });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scroll(0, 0);
    }
  };

  const getWelcomeBox = () => {
    return (
      <Box key={0} className={classes.slide}>
        <Welcome />
      </Box>
    );
  }

  const getStartBox = () => {
    return (
      <Box key={1} className={classes.slide}>
        <Start
          name={user.FirstName}
        />
      </Box>

    );
  }

  const getKnownTopicsBox = () => {
    return (
      <Box key={2} className={classes.slide}>
        <Typography variant="h4" className={clsx([styles.boldText, styles.centerText])}>Your Knowledge</Typography>
        <TopicSelect
          topicState={onboard[REGISTRATION_KEYS.KNOWN_TOPICS]}
          topicKey={REGISTRATION_KEYS.KNOWN_TOPICS}
          topicKey="knownTopics"
          header="Your Knowledge"
          subheading="Double check the topics you feel comfortable talking about."
          inMedicine={user.InMedicine}
          onChipClick={(topic) =>
            dispatch(setOnboardData({
              key: REGISTRATION_KEYS.KNOWN_TOPICS,
              value: topic
            }))
          }
        />
      </Box>
    );
  }

  const getInterestsBox = () => {
    return (
      <Box key={3} className={classes.slide}>
        <Typography variant="h4" className={clsx([styles.boldText, styles.centerText])}>Interests</Typography>
        <TopicSelect
          topicState={onboard[REGISTRATION_KEYS.INTEREST_TOPICS]}
          topicKey={REGISTRATION_KEYS.INTEREST_TOPICS}
          header="Interests"
          subheading="Double check the topics you are interesting in connecting with someone about."
          inMedicine={false}
          onChipClick={(topic) =>
            dispatch(setOnboardData({
              key: REGISTRATION_KEYS.INTEREST_TOPICS,
              value: topic
            }))
          }
        />
      </Box>
    );
  }

  const getAvailabilityBox = () => {
    return (
      <Box key={4} className={classes.slide}>
        <Typography variant="h4" className={clsx([styles.boldText, styles.centerText])}>General Availability</Typography>
        <Availability
          subheading="Set your general availability for the week. This gives us an idea of what times you would be okay to meet someone new. You can always change this in the settings."
          hideSaveButton
        />
      </Box>
    );
  }

  const getStatementBox = () => {
    return (
      <Box key={5} className={classes.slide}>
        <Typography variant="h4" className={clsx([styles.boldText, styles.centerText])}>Last Thing</Typography>
        <Info />
      </Box>
    );
  }

  const getRegistrationBox = () => {
    return (
      <Box key={6} className={classes.slide}>
        <Typography variant="h4" className={clsx([styles.boldText, styles.centerText])}>About You</Typography>
        <Registration errorMap={registrationErrorMap} />
      </Box>
    );
  }

  const getStepsElements = () => {
    return onboardSteps.map((step) => {
      switch (step) {
        case ONBOARD_STEPS.START:
          return getStartBox();
        case ONBOARD_STEPS.WELCOME:
          return getWelcomeBox();
        case ONBOARD_STEPS.REGISTRATION:
          return getRegistrationBox();
        case ONBOARD_STEPS.KNOWN_TOPICS:
          return getKnownTopicsBox();
        case ONBOARD_STEPS.INTERESTS:
          return getInterestsBox();
        case ONBOARD_STEPS.AVAILABILITY:
          return getAvailabilityBox();
        case ONBOARD_STEPS.STATEMENT:
          return getStatementBox();
      }
    });
  }

  const handleBack = () => {
    const step = onboardSteps[activeStep];
    if (step == ONBOARD_STEPS.STATEMENT) {
      setNextButtonLabel('Next');
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Layout showFooter={false} showHeader={false}>
      <Container maxWidth="sm">
        <ElementOrLoader loading={loading}>
          <SwipeableViews
            index={activeStep}
            disabled={true}
            styles={{paddingBottom: '50px'}}
          >
            {getStepsElements()}
          </SwipeableViews>
        </ElementOrLoader>
        <MobileStepper
          variant="dots"
          steps={onboardSteps.length}
          position="static"
          activeStep={activeStep}
          className={styles.bottomNav}
          nextButton={
            <Button size="small" onClick={handleNext}>
              {!nextLoading &&
                <React.Fragment>
                  {nextButtonLabel}
                  <KeyboardArrowRight/>
                </React.Fragment>
              }
              {nextLoading && <CircularProgress />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={handleBack} disabled={activeStep === 0 || nextLoading}>
              {activeStep != 0 &&
                <React.Fragment>
                  <KeyboardArrowLeft/>
                  Back
                </React.Fragment>
              }
            </Button>
          }
        />
      </Container>
    </Layout>
  );

}

export default Onboard;
