import React, { useState, useEffect, useReducer } from "react"
import {
  Container,
  Typography,
  TextField, Grid, FormControl, FormLabel, Select, MenuItem,
} from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import {setOnboardData} from "../system/system_slice"
import {BootstrapInput} from "../../../util"

import SharedStyles from '../../../shared_styles'
import { NOTIFICATION_LAYER, REGISTRATION_KEYS, USER_TYPE } from "../../../../lib/constants"



const GENDER_MAP = {
  1: 'Female',
  2: 'Male',
  3: 'Genderqueer / Non-Binary',
  4: 'Prefer not to say'
};

const RACE_MAP = {
  1: 'Black or African Descent',
  2: 'Hispanic or Latinx',
  3: 'White',
  4: 'Asian',
  5: 'Indian',
  6: 'Native Hawaiian or Other Pacific Islander',
  7: 'American Indian or Alaska Native',
  8: '2 or More Races',
  9: 'Prefer not to say'
}

// For the question - Are you a student?
const USER_TYPE_MAP = {
  2: 'Yes',
  1: 'No'
}

const MEDICINE_MAP = {
  2: 'Yes',
  1: 'No'
}

function getMenuItemsFromMap(map) {
  let elements = [];
  Object.keys(map).forEach((key) => {
    elements.push(
      <MenuItem key={Number(key)} value={Number(key)}>
        {map[key]}
      </MenuItem>
    );
  });
  return elements;
}

const FormControlProps = {
  'component':'fieldset',
  'margin':'normal',
  'fullWidth': true
};

const Registration = ({errorMap}) => {

  const dispatch = useDispatch();
  const styles = SharedStyles();
  const {user, networkInfo} = useSelector((state) => state.system);

  // Topics is an array of {id: Label} representing a single topic.
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState('');
  const [gender, setGender] = useState('');
  const [race, setRace] = useState('');
  const [linkedIn, setLinkedIn] = useState('https://www.linkedin.com/in/');

  useEffect(() => {
    if (networkInfo.ShowLinkedIn && user.LinkedIn) {
      setLinkedIn(user.LinkedIn);
      dispatch(setOnboardData({key: REGISTRATION_KEYS.LINKEDIN, value: user.LinkedIn}));
    }
    if(user.Email) {
      setEmail(user.Email);
      dispatch(setOnboardData({key: REGISTRATION_KEYS.EMAIL, value: user.Email}));
    }
    if (user.FirstName) {
      setFirstName(user.FirstName);
      setLastName(user.LastName);
      setGender(user.Gender);
      setRace(user.Race);
      setUserType(user.Type);
      dispatch(setOnboardData([
        {key:REGISTRATION_KEYS.FIRST_NAME, value: user.FirstName},
        {key:REGISTRATION_KEYS.LAST_NAME, value: user.LastName},
        {key:REGISTRATION_KEYS.GENDER, value: user.Gender},
        {key:REGISTRATION_KEYS.RACE, value: user.Race},
        {key:REGISTRATION_KEYS.USER_TYPE, value: user.Type},
      ]));
    }
  }, [])

  // Form  States and handlers.
  // TextField errors are handled by the browser.
  const handleTextField = event => {
    switch(event.target.name) {
      case REGISTRATION_KEYS.FIRST_NAME:
        setFirstName(event.target.value);
        break;
      case REGISTRATION_KEYS.LAST_NAME:
        setLastName(event.target.value);
        break;
      case REGISTRATION_KEYS.EMAIL:
        setEmail(event.target.value);
        break;
      case REGISTRATION_KEYS.LINKEDIN:
        setLinkedIn(event.target.value);
        break;
    }
  }

  const handleSelectChange = event => {
    const value = event.target.value;
    switch(event.target.name) {
      case REGISTRATION_KEYS.GENDER:
        setGender(value);
        break;
      case REGISTRATION_KEYS.RACE:
        setRace(value);
        break;
      case REGISTRATION_KEYS.USER_TYPE:
        setUserType(value);
        break;
    }
  }
  const saveFormValue = (event) => {
    dispatch(setOnboardData({key: event.target.name, value: event.target.value}));
  }

  return (
    <Container maxWidth="sm" style={{paddingTop:' 30px'}}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <TextField
            id={REGISTRATION_KEYS.FIRST_NAME}
            name={REGISTRATION_KEYS.FIRST_NAME}
            label="First Name"
            variant="outlined"
            fullWidth
            required
            value={firstName}
            onChange={handleTextField}
            margin="dense"
            error={errorMap[REGISTRATION_KEYS.FIRST_NAME]}
            onBlur={saveFormValue}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id={REGISTRATION_KEYS.LAST_NAME}
            name={REGISTRATION_KEYS.LAST_NAME}
            label="Last Name"
            variant="outlined"
            placeholder="Last Name"
            fullWidth
            required
            value={lastName}
            onChange={handleTextField}
            margin="dense"
            error={errorMap[REGISTRATION_KEYS.LAST_NAME]}
            onBlur={saveFormValue}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id={REGISTRATION_KEYS.EMAIL}
            name={REGISTRATION_KEYS.EMAIL}
            variant="outlined"
            label="Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleTextField}
            required
            margin="dense"
            error={errorMap[REGISTRATION_KEYS.EMAIL]}
            disabled={networkInfo.NotificationLayer == NOTIFICATION_LAYER.EMAIL}
            onBlur={saveFormValue}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            {...FormControlProps}
            error={errorMap[REGISTRATION_KEYS.GENDER]}
          >
            <FormLabel component="legend">Gender</FormLabel>
            <Select
              id={REGISTRATION_KEYS.GENDER}
              name={REGISTRATION_KEYS.GENDER}
              value={gender}
              onChange={handleSelectChange}
              required
              input={<BootstrapInput />}
              onBlur={saveFormValue}
            >
              {getMenuItemsFromMap(GENDER_MAP)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            {...FormControlProps}
            error={errorMap[REGISTRATION_KEYS.RACE]}
          >
            <FormLabel component="legend">Race / Ethnicity</FormLabel>
            <Select
              id={REGISTRATION_KEYS.RACE}
              name={REGISTRATION_KEYS.RACE}
              value={race}
              onChange={handleSelectChange}
              required
              input={<BootstrapInput />}
              onBlur={saveFormValue}
            >
              {getMenuItemsFromMap(RACE_MAP)}
            </Select>
          </FormControl>
        </Grid>
        {networkInfo.DefaultUserType == USER_TYPE.ANY &&
          <Grid item xs={12}>
            <FormControl
              {...FormControlProps}
              error={errorMap[REGISTRATION_KEYS.USER_TYPE]}
            >
              <FormLabel component="legend">Are you a student?</FormLabel>
              <Select
                id={REGISTRATION_KEYS.USER_TYPE}
                name={REGISTRATION_KEYS.USER_TYPE}
                value={userType}
                onChange={handleSelectChange}
                required
                displayEmpty
                input={<BootstrapInput/>}
                onBlur={saveFormValue}
              >
                {getMenuItemsFromMap(USER_TYPE_MAP)}
              </Select>
            </FormControl>
          </Grid>
        }
        {networkInfo.ShowLinkedIn &&
        <Grid item xs={12} style={{marginTop: '10px'}}>
          <FormLabel component="legend">What's your LinkedIn URL?</FormLabel>
          <TextField
            id={REGISTRATION_KEYS.LINKEDIN}
            name={REGISTRATION_KEYS.LINKEDIN}
            variant="outlined"
            label="LinkedIn URL"
            fullWidth
            value={linkedIn}
            onChange={handleTextField}
            required
            margin="dense"
            error={errorMap[REGISTRATION_KEYS.LINKEDIN]}
            onBlur={saveFormValue}
          />
        </Grid>
        }
      </Grid>
    </Container>
  )
}

export default Registration;
