import React, {useState, useEffect} from 'react';
import {
  Avatar,
  Box,
  Checkbox,
  Chip,
  Divider,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary, FormControlLabel, Grid, ListSubheader, MenuItem, TextField,
  Typography,
} from "@material-ui/core"
import axios from "axios"
import { API_ADDRESS, APP_BAR_STATE, TOPIC_MEDICINE_ID } from "../../../../lib/constants"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { setAppBar, setOnboardData } from "../system/system_slice"

const useStyle = makeStyles((theme) => ({
  container: {
    paddingBottom: theme.spacing(5)
  },
  chips: {
    display: 'flex',
      flexWrap: 'wrap',
  },
  topicSectionBox: {
    margin: theme.spacing(2, 1)
  },
  expansionDetailOverride: {
    flexWrap: 'wrap'
  },
  chip: {
    margin: theme.spacing(.25, .25),

  },
  selectedCount: {
    background: theme.palette.primary.main,
    color: 'white',
    width: '20px',
    height: '20px',
    fontSize: '12px'
  }
}));

const TOPIC_MENU_ITEMS = [];
function getTopicMenuItems(topics) {
  if (TOPIC_MENU_ITEMS.length != 0) {
    return TOPIC_MENU_ITEMS;
  }
  // This function handles 2 level of topics (TOPLEVEL and MAIN).
  topics.forEach((topLevel) => {
    TOPIC_MENU_ITEMS.push(
      <ListSubheader key={topLevel['id']} disableSticky>{topLevel['Label']}</ListSubheader>
    );
    topLevel['SubTopics'].forEach((main) => {
      TOPIC_MENU_ITEMS.push(
        <MenuItem key={main['id']} value={main['id']} >
          {main['Label']}
        </MenuItem>
      );
    });
  });
  return TOPIC_MENU_ITEMS;
}

const TopicSelect = ({topicKey, topicState, header, subheading, onChipClick, inMedicine}) => {
  const classes = useStyle();
  const {canonicalTopics} = useSelector((state) => state.system);
  const [expanded, setExpanded] = useState(false);

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(()=> {
    if (canonicalTopics.length == 1) {
      setExpanded(canonicalTopics[0]['Label']);
    }
  }, [canonicalTopics])

  const getTopicChips = () => {
    let containing_elements = [];
    canonicalTopics.forEach((topLevel) => {
      // Either only show the medschool topics or don't show them at all
      // depending on if the person is in the medicine field or not.
      if (inMedicine && topLevel['id'] != TOPIC_MEDICINE_ID) {
        return;
      } else if (!inMedicine && topLevel['id'] == TOPIC_MEDICINE_ID) {
        return;
      }

      let elements = [];
      let selected_count = 0;
      topLevel['SubTopics'].forEach((main) => {
        let variant = 'outlined';
        if (topicState && topicState[main["id"]]) {
          variant = "default";
          selected_count++;
        }
        elements.push(
          <Chip
            key={main["id"]}
            label={main["Label"]}
            className={classes.chip}
            onClick={()=>onChipClick(main)}
            variant={variant}
            color="primary"
          />
        );
      });
      containing_elements.push(
        <ExpansionPanel key={topLevel['Label']} expanded={expanded === topLevel['Label']} onChange={handleExpandedChange(topLevel['Label'])}>
          <ExpansionPanelSummary>
            <Grid item xs={6}>
              <Typography component="h6" variant="subtitle2">
                {topLevel['Label']}
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              {selected_count > 0 &&
                <Avatar className={classes.selectedCount}>{selected_count}</Avatar>
              }
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.expansionDetailOverride}>
            {elements}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    })
    return (
      <Box className={classes.topicSectionBox}>
        {containing_elements}
      </Box>

    );
  }

  return (
    <Box className={classes.container}>
      <Typography variant="body2">
        {subheading}
      </Typography>
      <Grid item xs={12}>
        {getTopicChips()}
      </Grid>
    </Box>
  );
}

export default TopicSelect;
