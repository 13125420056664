import React, { useEffect, useState, useReducer } from "react"
import moment from 'moment';
import Layout from '../../../layout'
import SEO from '../../../seo'
import {
  Button,
  Chip,
  Container,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Grid,
  Typography,
  Paper, CircularProgress, Box, FormControlLabel, Checkbox,

} from "@material-ui/core"

import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  TimePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { ElementOrLoader } from "../../../util"
import axios from "axios"
import { API_ADDRESS, APP_BAR_STATE, LOADING_KEYS } from "../../../../lib/constants"
import { getAuthToken } from "../../../../lib/auth"
import { useDispatch, useSelector } from 'react-redux'
import { setAppBar, setLoading, setAvailability } from '../system/system_slice'
import {apiGet, apiSaveAvailability} from "../../../../lib/api"


const serializeTime = (momentDate) => {
  return momentDate.toISOString().substr(0, 19)+'Z';
}
const getDefaultTimeState = () => {
  const curDay = new Date().getDay();
  // Normalize to Sunday.
  const sunday_start = moment().hour(12).minute(0).second(0).millisecond(0).day(0);
  const sunday_end = moment().hour(20).minute(0).second(0).millisecond(0).day(0);
  const defaultTimes = {};
  for (let i=0; i<7; i++) {
    defaultTimes[i] = {
      start_time: serializeTime(moment(sunday_start).day(i)),
      end_time: serializeTime(moment(sunday_end).day(i)),
      repeat: true,
      unavailable: false
    }
  }
  return defaultTimes;
}
const Weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const getDisplayTime = ({start_time, end_time, unavailable}) => {
  if (unavailable) {
    return (
      <Chip
        label="Unavailable"
        />
    )
  }
  const startTimeDate = new Date(start_time);
  const endTimeDate = new Date(end_time);
  let start_format = 'h';
  if (startTimeDate.getMinutes()) {
    start_format+=':mm'
  }
  // If start isn't on the same side of noon as end.
  if ((startTimeDate.getHours() < 12) ^ (endTimeDate.getHours() < 12)) {
    start_format += ' a';
  }

  let end_format = 'h';
  if (endTimeDate.getMinutes()) {
    end_format+=":mm";
  }
  end_format+=' a';

  return moment(startTimeDate).format(start_format)+" - "+moment(endTimeDate).format(end_format);
}

const SAVE_BUTTON_LOADING = 'availabilitySaveButtonLoading';
const START_LOADING = 'availabilityStartLoading';

const Availability = ({subheading, hideSaveButton}) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(-1);
  const {loading, availability} = useSelector((state) => state.system)
  // Startup
  useEffect(()=> {
    dispatch(setAppBar({
      state: APP_BAR_STATE.BACK_LABEL,
      label: 'General Availability',
      backLocation: '/app/settings'
    }));

    // TODO: Load availabilities into store if they're not there already.
    dispatch(setLoading({key:START_LOADING, value: true}));
    apiGet('availability/')
      .then((response)=> {
        // Either use the ones form the server or the default.
        dispatch(setAvailability({
          field: 'reset',
          value: response.error?getDefaultTimeState():response.availabilities
        }));
      })
      .finally(()=>{
        dispatch(setLoading({key:START_LOADING, value: false}));
      });

  }, [])

  const handleExpandedChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : -1);
  };

  const handleTimeChange = (field, day, newDate) => {
    if (!newDate || !newDate.isValid()) return;
    dispatch(setAvailability({
      field: field,
      day: day,
      value: serializeTime(newDate.day(day))
    }))
  }

  const getTimeMapUI = () => {
    let elements = [];
    for (const day in availability) {
      const expansionPanel = (
        <ExpansionPanel key={day} expanded={expanded == day} onChange={handleExpandedChange(day)} disabled={loading[LOADING_KEYS.SAVE_AVAILABILITY]}>
          <ExpansionPanelSummary>
            <Grid item xs={6}>
              <Typography component="h6" variant="subtitle2">
                {Weekdays[day]}
              </Typography>
            </Grid>
            <Grid item xs={6} align="right">
              {getDisplayTime(availability[day])}
            </Grid>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid container>
                <Grid item xs={12} container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      Start Time
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <KeyboardTimePicker
                      margin="dense"
                      value={new Date(availability[day].start_time)}
                      onChange={(newDate) => handleTimeChange( 'start_time', day, newDate)}
                      disabled={availability[day].unavailable}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="body2">
                      End Time
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align="right">
                    <KeyboardTimePicker
                      margin="dense"
                      value={new Date(availability[day].end_time)}
                      onChange={(newDate) => handleTimeChange( 'end_time', day, newDate)}
                      disabled={availability[day].unavailable}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      minutesStep={30}
                    />
                  </Grid>
                </Grid>
                {/*<Grid item xs={12} container alignItems="center">*/}
                {/*  <Grid item xs={6}>*/}
                {/*    <Typography variant="body2">*/}
                {/*      Repeat Weekly*/}
                {/*    </Typography>*/}
                {/*  </Grid>*/}
                {/*  <Grid item xs={6} align="right">*/}
                {/*    <Switch*/}
                {/*      checked={timeMap[day].repeat}*/}
                {/*      onChange={() => dispatchTimeMap({field:'repeat', day: day, value: !timeMap[day].repeat})}*/}
                {/*      disabled={timeMap[day].unavailable}*/}
                {/*    />*/}
                {/*  </Grid>*/}
                {/*</Grid>*/}
                <Grid item xs={12} container alignItems="center">
                  <FormControlLabel
                    onChange={(event) => dispatch(setAvailability({field: 'unavailable', day: day, value: event.target.checked}))}
                    control={
                      <Checkbox
                        color="primary"
                      />
                    }
                    label={"I'm unavailable on "+Weekdays[day]+"s"}
                  />
                </Grid>
              </Grid>
            </MuiPickersUtilsProvider>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
      elements.push(expansionPanel);
    }
    return (
      <React.Fragment>
        {elements}
      </React.Fragment>
    );

  }
  return (
    <Container maxWidth="sm">
      {subheading &&
        <Typography variant="subtitle2">
          {subheading}
        </Typography>
      }
      {!subheading &&
        <Typography variant="subtitle2">
          Set your daily availability rules below. These are the times that we
          will allow other users to request.
        </Typography>
      }
      <ElementOrLoader loading={loading[LOADING_KEYS.LOAD_AVAILABILITY]}>
        {getTimeMapUI()}
        {!hideSaveButton &&
          <Button
            variant="contained"
            color="primary"
            onClick={() => apiSaveAvailability(dispatch)}
          >
            {loading[LOADING_KEYS.SAVE_AVAILABILITY] ? <CircularProgress style={{color:'white', borderColor:'white'}} size={24}/> : 'Save Updates'}
          </Button>
        }
      </ElementOrLoader>
    </Container>
  );
}

export default Availability;

