import React, {useState, useEffect} from 'react'
import {
  Avatar,
  Box, Container, FormLabel,
  Grid, Link, List, ListItem, ListItemAvatar, ListItemText,
  makeStyles, MenuItem,
  Paper, Select,
  Typography,
} from "@material-ui/core"
import { BootstrapInput } from "../../../util"
import { setOnboardData } from "../system/system_slice"
import SharedStyles from '../../../shared_styles'

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '75%',
    maxWidth: '300px',
    marginBottom: theme.spacing(3)
  },
  hand: {
    width: '20%',
    maxWidth: '90px',
    marginBottom: theme.spacing(3)
  },

  painPointGraphic: {
    width: '5vw'
  },
  heading: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '1.25em'
  }

}))

const info = [
  {
    image: 'https://greenwoodx.s3.us-east-2.amazonaws.com/join_image.svg',
    label: 'Warm Introductions',
    text: 'No more sending cold emails and hoping someone replies. We\'ll find someone who wants to talk about your specific career interest.'
  },
  {
    image: 'https://greenwoodx.s3.us-east-2.amazonaws.com/request_image.svg',
    label: 'Automated Scheduling',
    text: 'Reduce the back and forth emails coordinating calendars. Pick the time you are available to talk and we will handle the rest.',
  },
  {
    image: 'https://greenwoodx.s3.us-east-2.amazonaws.com/connect_image.svg',
    label: 'Personalized Network',
    text: 'Meet people, reconnect, and manage your new relationships all on Greenwoodx.'
  },
];


const Welcome = ({name}) => {
  const classes = useStyles();
  const styles = SharedStyles();

  const getList = () => {
    return (
      <List>
        {
          info.map((item, index) => (
            <ListItem key={index}>
              <ListItemAvatar className={classes.paintPointGraphic}>
                <Avatar
                  src={item.image}
                />
              </ListItemAvatar>
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{className:classes.heading}}
                secondary={item.text} />
            </ListItem>
          ))
        }
      </List>
    );
  }

  return (
    <Box className={styles.centerElements} style={{flexDirection:'column'}}>
      <img src="https://greenwoodx.s3.us-east-2.amazonaws.com/purple_logo.svg" className={classes.logo}/>
      {getList()}
    </Box>
  );
}

export default Welcome;
